<template>
    <v-container>
       <template>
        <v-card 
        v-model="tableComputerDisplayDetails"
        scrollable
        v-for="(items, index) in appendComputerInfo"
                            :key="index">
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Asset Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                  <a :href="items.displayImage" target="_blank">
                        <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 80%;
                            ">
                    </a> 
                <v-simple-table class="table-details-info" >
                      <template v-slot:default>
                        <tbody >
                          <v-col >
                            <div
                              style="white-space: nowrap"
                              class="text-subtitle-1 text-bold grey--text"
                            >
                              General Information
                            </div>
                          </v-col>
                          <tr v-for="item in items.generalInfo"
                            :key="item.name">
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                          <v-col >
                            <div
                              style="white-space: nowrap"
                              class="text-subtitle-1 text-bold grey--text"
                            >
                              Specification
                            </div>
                          </v-col>
                          <tr v-for="item in items.specInfo"
                            :key="item.name">
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-card-text>            
            </v-card>
            <v-card 
        v-model="tableMonitorDisplayDetails"
        scrollable
        v-for="(items, index) in appendMonitorInfo"
                            :key="index">
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Asset Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                  <a :href="items.displayImage" target="_blank">
                        <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 80%;
                            ">
                    </a> 
                <v-simple-table class="table-details-info" >
                      <template v-slot:default>
                        <tbody >
                          <tr v-for="item in items"
                            :key="item.name">
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-card-text>            
            </v-card>
            <v-card 
        v-model="tableAccessoriesDisplayDetails"
        scrollable
        v-for="(items, index) in appendAccessoriesInfo"
                            :key="index">
                <v-card-title class="title-bar-modul">
                <span class="text-h5">Asset Detail</span><v-icon></v-icon>
                </v-card-title>

                <v-card-text>
                  <a :href="items.displayImage" target="_blank">
                        <img :src="displayImage? displayImage : nullImage.src" style="display: block;
                            margin-left: auto;
                            margin-right: auto;
                            width: 80%;
                            ">
                    </a> 
                <v-simple-table class="table-details-info" >
                      <template v-slot:default>
                        <tbody >
                          <tr v-for="item in items"
                            :key="item.name">
                            <td class="font-weight-bold">{{ item.key }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                </v-card-text>            
            </v-card>
    </template>
 </v-container>
</template>


<script>
import axios from "axios";
import store from '@/store'

export default {
  data: () => ({
    tableComputerDisplayDetails: false,
    tableMonitorDisplayDetails: false,
    tableAccessoriesDisplayDetails: false,
    appendComputerInfo:[],
    appendMonitorInfo:[],
    appendAccessoriesInfo:[],
    assetFiltered: [],
    assetInfo: [],
    serialNo:"",
    displayComputerDetails: {
       generalInfo: [ 
        { key: "Type", value: "" },
        { key: "Employee Name", value: ""},
        { key: "Brand", value: "" },
        { key: "Asset Tag", value: "" },
        { key: "Serial Number", value: "" },
        { key: "Model", value: "" },
        { key: "Warranty Start Date", value: "" },
        { key: "Warranty End Date", value: "" },
        { key: "Purchased By", value: "" },
        { key: "Purchased Date", value: "" },
        { key: "Disposed By", value: "" },
        { key: "Registered By", value: "" },
        { key: "Remarks", value: "" },
        { key: "Status", value: "" },
      ],
      specInfo: [
        {key: "Processor", value: ""},    
        {key: "Mac LAN", value: ""},    
        {key: "Mac WiFi", value: ""},    
        {key: "RAM", value: ""},    
        {key: "Storage", value: ""}, 
      ], 
      displayImage:"",
      },
      nullImage: {
            src: require('@/assets/img/meetingRooms/none-image.jpg')
        },
      displayMonitorDetails: [
      { key: "Type", value: "" },
      { key: "Employee Name", value: ""},
      { key: "Brand", value: "" },
      { key: "Asset Tag", value: "" },
      { key: "Serial Number", value: "" },
      { key: "Model", value: "" },
      { key: "Monitor Size", value: "" },
      { key: "Warranty Start Date", value: "" },
      { key: "Warranty End Date", value: "" },
      { key: "Purchased By", value: "" },
      { key: "Purchased Date", value: "" },
      { key: "Disposed By", value: "" },
      { key: "Registered By", value: "" },
      { key: "Remarks", value: "" },
      { key: "Status", value: "" },
      {displayImage:""},
    ],
    displayAccessoriesDetails: [
      { key: "Type", value: "" },
      { key: "Employee Name", value: ""},
      { key: "Brand", value: "" },
      { key: "Item", value: "" },
      { key: "Asset Tag", value: "" },
      { key: "Serial Number", value: "" },
      { key: "Model", value: "" },
      { key: "Warranty Start Date", value: "" },
      { key: "Warranty End Date", value: "" },
      { key: "Purchased By", value: "" },
      { key: "Purchased Date", value: "" },
      { key: "Disposed By", value: "" },
      { key: "Registered By", value: "" },
      { key: "Remarks", value: "" },
      { key: "Status", value: "" },
      {displayImage:""},
    ],
  }),
  methods: {
      getAllAsset() {
           axios
            .get(process.env.VUE_APP_API_URL + "asset/all", {
                headers: {
                Authorization: "Bearer " + this.$store.getters.token,
                },
            })
            .then((response) => {
                let data = response.data;
                this.assetInfo = data;
                this.selectedDataAsset();
            })
            .catch((error) => {
                console.log(error);
            });    
      }, 
      selectedDataAsset() {
        this.assetFiltered = this.assetInfo.filter((x) => x.serial_no == this.serialNo);

        for (let j = 0; j < this.assetFiltered.length; j++) {

        if(this.assetFiltered[j].assetname == "Laptop" || this.assetFiltered[j].assetname == "Desktop" || this.assetFiltered[j].assetname == "Server") {
            this.displayComputerDetails.generalInfo[0].value = this.assetFiltered[j].assetname;
            this.displayComputerDetails.generalInfo[1].value = this.assetFiltered[j].empname;
            this.displayComputerDetails.generalInfo[2].value = this.assetFiltered[j].brand;
            this.displayComputerDetails.generalInfo[3].value = this.assetFiltered[j].asset_tag;
            this.displayComputerDetails.generalInfo[4].value = this.assetFiltered[j].serial_no;
            this.displayComputerDetails.generalInfo[5].value = this.assetFiltered[j].model;
            this.displayComputerDetails.generalInfo[6].value = this.convertDateString(this.assetFiltered[j].start_date_warranty);
            this.displayComputerDetails.generalInfo[7].value = this.convertDateString(this.assetFiltered[j].end_date_warranty);
            this.displayComputerDetails.generalInfo[8].value = this.assetFiltered[j].purchaser_name;
            this.displayComputerDetails.generalInfo[9].value =this.convertDateString(this.assetFiltered[j].date_purchased);
            this.displayComputerDetails.generalInfo[10].value = this.assetFiltered[j].disposed_name;
            this.displayComputerDetails.generalInfo[11].value = this.assetFiltered[j].registrar_name;
            this.displayComputerDetails.generalInfo[12].value = this.assetFiltered[j].remarks;
            this.displayComputerDetails.generalInfo[13].value = this.assetFiltered[j].status;

            
            this.displayComputerDetails.specInfo[0].value =  this.assetFiltered[j].processor;
            this.displayComputerDetails.specInfo[1].value =  this.assetFiltered[j].mac_address_lan;
            this.displayComputerDetails.specInfo[2].value =  this.assetFiltered[j].mac_address_wifi;
            this.displayComputerDetails.specInfo[3].value =  this.assetFiltered[j].ram;
            this.displayComputerDetails.specInfo[4].value =  this.assetFiltered[j].storage;

            this.displayComputerDetails.displayImage = this.assetFiltered[j].picture;

            this.appendComputerInfo.push(this.displayComputerDetails)

        } else if(this.assetFiltered[j].assetname == "Monitor") {
            this.displayMonitorDetails[0].value = this.assetFiltered[j].assetname;
            this.displayMonitorDetails[1].value = this.assetFiltered[j].empname;
            this.displayMonitorDetails[2].value = this.assetFiltered[j].brand;
            this.displayMonitorDetails[3].value = this.assetFiltered[j].asset_tag;
            this.displayMonitorDetails[4].value = this.assetFiltered[j].serial_no;
            this.displayMonitorDetails[5].value = this.assetFiltered[j].model;
            this.displayMonitorDetails[6].value = this.assetFiltered[j].monitor_size;
            this.displayMonitorDetails[7].value = this.convertDateString(this.assetFiltered[j].start_date_warranty);
            this.displayMonitorDetails[8].value =this.convertDateString(this.assetFiltered[j].end_date_warranty);
            this.displayMonitorDetails[9].value = this.assetFiltered[j].purchaser_name;
            this.displayMonitorDetails[10].value = this.convertDateString(this.assetFiltered[j].date_purchased);
            this.displayMonitorDetails[11].value = this.assetFiltered[j].disposed_name;
            this.displayMonitorDetails[12].value = this.assetFiltered[j].registrar_name;
            this.displayMonitorDetails[13].value = this.assetFiltered[j].remarks;
            this.displayMonitorDetails[14].value = this.assetFiltered[j].status;

            this.displayMonitorDetails.displayImage = this.assetFiltered[j].picture;

            this.appendMonitorInfo.push(this.displayMonitorDetails)

        } else if(this.assetFiltered[j].assetname == "Accessories" ) {
            this.displayAccessoriesDetails[0].value = this.assetFiltered[j].assetname;
            this.displayAccessoriesDetails[1].value = this.assetFiltered[j].empname;
            this.displayAccessoriesDetails[2].value = this.assetFiltered[j].brand;
            this.displayAccessoriesDetails[3].value = this.assetFiltered[j].item;
            this.displayAccessoriesDetails[4].value = this.assetFiltered[j].asset_tag;
            this.displayAccessoriesDetails[5].value = this.assetFiltered[j].serial_no;
            this.displayAccessoriesDetails[6].value = this.assetFiltered[j].model;
            this.displayAccessoriesDetails[7].value = this.convertDateString(this.assetFiltered[j].start_date_warranty);
            this.displayAccessoriesDetails[8].value = this.convertDateString(this.assetFiltered[j].end_date_warranty);
            this.displayAccessoriesDetails[9].value = this.assetFiltered[j].purchaser_name;
            this.displayAccessoriesDetails[10].value =this.convertDateString(this.assetFiltered[j].date_purchased);
            this.displayAccessoriesDetails[11].value = this.assetFiltered[j].disposed_name;
            this.displayAccessoriesDetails[12].value = this.assetFiltered[j].registrar_name;
            this.displayAccessoriesDetails[13].value = this.assetFiltered[j].remarks;
            this.displayAccessoriesDetails[14].value = this.assetFiltered[j].status;

            this.displayAccessoriesDetails.displayImage = this.assetFiltered[j].picture;

            this.appendAccessoriesInfo.push(this.displayAccessoriesDetails)
        }  
        }
      }
  },
  mounted() {
    if (localStorage.user) {
        store.dispatch('checkToken')
        location.href 
    } else {
        location.href='/login?redirecturl='+location.href
    }
        let url = decodeURIComponent(location.href)
        this.serialNo = url.substr(url.lastIndexOf("=")+1);
        this.getAllAsset();
  }
}
</script>
